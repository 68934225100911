@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

body {
  background-color: #07091D;
  color: #fff;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

ul li {
  list-style: none;
}

.app {
  width: 100vw;
  overflow: hidden;
}

a {
  color: #d3e7eb;
  text-decoration: none;
}
