.section {
  height: 480px;
  position: relative;
  overflow-x: scroll;
  display: flex;
}

.wrap {
  position: absolute;
  overflow-x: visible;
  display: flex;
  column-gap: 30px;
  justify-content: center;
}