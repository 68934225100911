@import "src/assets/styles/default";
.wrap {
  margin-top: -150px;
  padding: 0 20px;
  display: grid;
  row-gap: 30px;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px){
  .wrap {
    grid-template-columns: 1fr;
  }
}