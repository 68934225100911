@import "src/assets/styles/default";

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0e1114;
  z-index: 51;
  padding-top: 30px;
  text-align: center;
  transition: 0.5s;
}

.title {
  font-size: 47px;
  font-family: $title;
  margin-bottom: 50px;
}

.btnWrap {
  display: flex;
  justify-content: center;
}

.subTitle {
  margin-top: 50px;
  margin-bottom: 15px;
  font-size: 20px;
  font-family: $text;
}

.list {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  column-gap: 12px;
}

.menu {
  border-top: 1px solid rgba(255,255,255, 0.1);
  margin: 20px;
}

.menu {
  font-family: $text;
  font-size: 17px;
  padding-top: 30px;
  display: grid;
  row-gap: 25px;
}