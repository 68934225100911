@import "src/assets/styles/default";

.wrap {
  margin-top: -200px;
  z-index: 20;
}

.list {
  display: grid;
  row-gap: 30px;
}
