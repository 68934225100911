@import "src/assets/styles/default";

.wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-top: -700px;
  padding-bottom: 200px;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(7, 9, 29, 1),rgba(7, 9, 29, .8) 50%, rgba(7, 9, 29, 0.5) 75%);
}

.content {
  padding-top: 950px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 700px;
  column-gap: 30px;
}

.info {
  padding-top: 40px;
}

.infoText {
  font-family: $text;
  font-weight: 400;
  font-size: 17px;
  line-height: 31px;
  margin-top: 30px;
}

.blackSection {
  background: #000;
  padding: 20px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 70px 1fr;
  align-items: center;
  border-radius: 10px;
}

.blackImg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blackSection p {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.greenBg {
  position: absolute;
}

.additional {
  height: 425px;
  width: 679px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.additionalMobile {
  width: 355px;
  height: 649px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.additionalContent {
  height: 425px;
  width: 679px;
  position: absolute;
  padding: 30px 20px;
}

.additionalMobileContent {
  position: absolute;
  width: 355px;
  height: 649px;
  padding: 30px 20px;
}

.additionalMobileTitle {
  font-family: $title;
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;
}

.additionalBack {
  position: absolute;
}

.additionalMobileBack {
  position: absolute;
}

.additionalTitle {
  font-family: $title;
  font-weight: 400;
  font-size: 40px;
  line-height: 25px;
}

.additionalList {
  margin-top: 31px;
  display: grid;
  row-gap: 12px;
}

.additionalItem {
  display: grid;
  grid-template-columns: 250px 1fr;
}

.additionalSpanTitle {
  font-family: $text;
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.4);
}

.additionalSpanText {
  font-family: $text;
  font-size: 15px;
  line-height: 25px;
}

.additionalMobileListItem {
  display: flex;
  flex-direction: column;
}

.additionalMobileList {
  display: grid;
  row-gap: 20px;
  margin-top: 30px;
}

@media screen and (max-width: 1100px) {
  .container {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  .infoText {
    margin-top: 10px;
  }

  .additional {
    margin: 0 auto;
  }

  .blackSection {
    margin-top: 20px;
  }

  .wrap {
    margin-top: -820px;
  }

  .nftText {
    max-width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .additionalMobile {
    width: 100%;
    margin: 0;
  }

  .additionalMobileBack {
    width: 100%;
    height: 649px;
  }

  .additionalMobileContent {
    width: 310px;
  }
}