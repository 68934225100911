@import "src/assets/styles/default";

.container {
  margin-top: 60px;
  padding: 50px 0;
  font-family: $text;
}

.img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.termsTitle {
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 34px;
  font-family: $titleSecondary;
}



.content {
  max-width: 700px;
  margin: 0 auto;
  font-size: 18px;
}

.content h4 {
  margin-top: 30px;
  font-size: 26px;
  font-family: $titleSecondary;
  color: #fff;
}

.content h4.small {
  font-size: 21px;
  color: #d3e7eb;
}


.content h4.shareTitle {
  margin-top: 0 !important;
  font-family: $titleSecondary;
}

.content h3 {
  margin-top: 30px;
  font-size: 30px;
  font-family: $titleSecondary;
}

.content p {
  margin-top: 10px;
  color: #cdcdd1;
}

.basic {
  display: grid;
  row-gap: 7px;
}

.date {
  font-style: italic;
  font-size: 14px;
  color: #fff !important;
  margin-top: 30px;
  display: block;
}