

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(800px);
  transform: perspective(800px);
  max-width: 100%;
}

.hover-img {
  max-width: 100%;
  border-radius: 10px;
}

.card {
  border-radius: 10px;
  box-shadow: 20px 20px 75px rgba(0, 0, 0, 0.5);
  // background: rgb(254,211,48);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.1s;
  transition: 0.1s;


  .card__gloss {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform: translateZ(1px);
    z-index: 2;
  }

}