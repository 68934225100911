@import "src/assets/styles/default";

.itemSection {
  height: 372px;
  width: 910px;
  margin: 0 auto;
}

.itemWrap {
  height: 372px;
  width: 910px;
  margin: 0 auto;
  position: relative;
}

.itemBlock {
  position: absolute;
  z-index: 44;
}

.itemContainer {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.blockBg {
  position: absolute;
}

.blockBgReverse {
  transform: rotate(180deg);
}

.itemContent {
  padding-left: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.itemContent h4 {
  font-family: $title;
  font-weight: 400;
  font-size: 42px;
  line-height: 71px;
}

.itemContent p {
  font-family: $text;
  font-size: 15px;
  line-height: 25px;
}

.itemImageWrap {
}

.itemImageWrap img {
  border-radius: 15px;
}

.dec {
  position: absolute;
  bottom: 0;
  left: -2px;
}

.decReverse {
  left: auto;
  right: 0px;
  top: 0;
  bottom: auto;
  transform: rotate(180deg);
}

.itemImageWrapReverse {
  grid-column: 1;
  grid-row: 1;
}

.itemContentReverse {
  grid-column: 2;
  grid-row: 1;
  padding-right: 30px;
  padding-left: 0;
}
