.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.95);
  top: 0;
  left: 0;
  z-index: 60;
  display: flex;
  align-items: center;
}