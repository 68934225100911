@import "src/assets/styles/default";

.sections {
  display: grid;
}

.info {
  height: 440px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}

.men {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 5;
}

.child {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
}

.infoContainer {
  width: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2),rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.6) 75%);
  height: 100%;
  border-radius: 20px;
}

.infoContent {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 20px;
  z-index: 10;
  position: absolute;
}

.infoTitle {
  font-family: $title;
  font-size: 75px;
  line-height: 81px;
  color: $white;
  margin-bottom: 40px;
}

.textInfo, .textInfo2 {
  font-family: $text;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: $white;
}

.textInfo {
  max-width: 770px;
  margin: 0 auto;
}

.textInfo2 {
  max-width: 1004px;
  margin: 0 auto;
  padding-top: 20px;
}

.greenBorder {
  border-bottom: 2px solid $lightGreen;
}


.offer {
  scale: 0.8;
  transition: 1s;
  transform: translateY(0px);
}

.offerActive {
  scale: 1;
  transform: translateY(-50px);
}

@media screen and (max-width: 1200px){
  .wrap {
    width: 100%;
  }

  .background {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 1050px){
  .text {
    text-align: center;
    font-size: 40px;
    line-height: 55px;
  }

  .content {
    padding: 50px;
  }


  .buttons {
    flex-direction: column;
    row-gap: 30px;
  }

  .background {
    width: 100%;
    height: 560px;
  }

  .men {
    width: 400px;
  }

  .child {
    width: 300px;
  }
}

@media screen and (max-width: 800px){
  .text {
    font-size: 35px;
    line-height: 45px;
  }

  .sections {
    row-gap: 120px;
  }

  .wrap {
    transform: translateY(-0px);
  }

  .infoTitle {
    font-size: 48px;
    line-height: 55px;
  }

  .info {
    grid-row: 1;
    margin-top: -150px;
  }

  .textInfo, .textInfo2 {
    font-size: 18px;
    line-height: 24px;
  }

  .wrap {
    height: 620px;
  }
}



@media screen and (max-width: 650px){
  .background {
    height: 620px;
  }

  .info {
    height: 600px;
  }

  .men {
    width: 200px;
  }

  .child {
    width: 150px;
  }

  .infoContent {
    justify-content: flex-start;
  }

  .infoTitle {
    font-size: 45px;
    line-height: 55px;
  }

  .textInfo, .textInfo2 {
    font-size: 15px;
    line-height: 24px;
  }
}


@media screen and (max-width: 500px){
  .text {
    font-size: 22px;
    line-height: 32px;
  }
}

