.wrap {
  margin-top: -300px;
}

.faqText {
  display: block;
  max-width: 100%;
  margin-bottom: 80px;
}

.list {

}

@media screen and (max-width: 800px) {
  .faqText {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 60px;
  }
}
