@import "src/assets/styles/default";

.wrap {
  padding: 100px 0;
}

.creationText {
  display: block;
  max-width: 100%;
}

.text {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  margin-top: 30px;
}

.list {
  display: grid;
  row-gap: 40px;
  margin-top: 50px;
}

.item {
}

.iconWrap {
  height: 50px;
  width: 50px;
  background: rgba(255,255,255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.itemText {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  max-width: 500px;
  margin: 20px auto;

}

.itemTitle {
  font-family: $text;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
}

.poster {
  width: 100%;
  display: block;
  max-width: 500px;
  border-radius: 15px;
  overflow: hidden;
  margin: 0 auto;
}