
.wrap {
  margin-top: -500px;
}

.gradient {
  padding-top: 700px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(7, 9, 29, 1),rgba(7, 9, 29, .8) 50%, rgba(7, 9, 29, 1) 75%);
  padding-bottom: 300px;
}