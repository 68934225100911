@import 'src/assets/styles/default';

.wrap {
  padding: 40px 0;
}

.headerWrap {
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
}

.container {
  overflow-x: scroll;
  transition: 1s;
  margin-top: -40px;
}

.item {
  width: 271px;
  height: 466px;
  position: relative;
}

.sliderContainer {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.sliderContent h4 {
  font-family: $text;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
}

.sliderContent p {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  margin-top: 20px;
}

.universeTextImg {
  display: block;
  margin-top: 0;
  max-width: 100%;
  margin-bottom: 20px;
}

.universeText {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
}

.sliderContainerPoster {
  margin: 0 auto;
}

.smallContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  text-align: center;
  padding-left: 15px;
}

.itemWrap {
  width: 501px;
  height: 425px;
  position: relative;
}

.sliderImg {
  position: absolute;
}

.list {
  display: flex;
  column-gap: 29px;
  width: fit-content;
  padding: 50px 0;
  padding-right: 60vw;
  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: 1fr 140px;
  margin-bottom: 50px;
  column-gap: 40px;
  align-items: center;
}

.line {
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.lineActive {
  height: 1px;
  background-color: $lightGreen;
  transition: 0.5s;
}

.arrowLeft {
  transform: rotateY(180deg);
  cursor: pointer;
}

.arrowRight {
  cursor: pointer;
}

.blackBg {
  transform: translate(0px, -75px);
  position: absolute;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

