@import "src/assets/styles/default";

.wrap {
  position: relative;
}

.imgBackground {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(
                  to bottom,
                  rgba(7, 9, 29, 1),
                  rgba(7, 9, 29, .95) 20%,
                  rgba(7, 9, 29, 0.6) 35%,
                  rgba(7, 9, 29, .8) 50%,
                  rgba(7, 9, 29, 0.97) 75%
  )
;
  z-index: 4;
}

.content {
  padding-top: 30px;
  padding-bottom: 450px;
}

.mintText {
  max-width: 100%;
  display: block;
}

.list {
  margin-top: 90px;
  column-gap: 50px;
  row-gap: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.count {
  font-family: $title;
  color: $lightGreen;
  font-weight: 400;
  font-size: 39px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.itemDescription {
  font-family: $text;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  max-width: 319px;
  margin-bottom: 10px;
  height: 130px;
}

.itemTerms {

}

.termItem {
  display: grid;
  grid-template-columns: 30px 1fr;
}

.coolIcon {
  transform: translate(-16px, -11px);
}

.btn {
  margin-top: 80px;
}

.mobileBtn {
  display: none;
}

.termText {
  font-family: $text;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
}


@media screen and (max-width: 880px){
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px){
  .list {
    grid-template-columns: 1fr;
  }

  .itemDescription {
    max-width: 500px;
    height: auto;
    margin-bottom: 15px;
  }

  .btn {
    display: none;
  }

  .mobileBtn {
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }


}