@import 'src/assets/styles/default';

.wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 300px;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(7, 9, 29, 1),rgba(7, 9, 29, .8) 50%, rgba(7, 9, 29, 0.5) 75%);
}

.content {
  padding-top: 200px;
}

.firstDec {
  height: 112px;
  width: 450px;
  border: 1px solid rgba(255,255,255, 0.8 );
  border-radius: 10px;
}

.titleFirstWrap {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.titleSecondWrap {
  margin-top: 55px;
  display: flex;
  align-items: center;
  column-gap: 50px;
  transform: translateX(-300px);
}

.list {
  margin-top: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  row-gap: 90px;
}

.itemIconWrap {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.itemText {
  font-family: $text;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: $white;
  max-width: 270px;
}

.itemIconWrap img {
  position: absolute;
}



@media screen and (max-width: 1050px) {
  .titleFirstWrap img, .titleSecondWrap img {
    height: 70px;
  }

  .list {
    grid-template-columns: 1fr 1fr;
    row-gap: 25px;
  }
}

@media screen and (max-width: 700px) {
  .firstDec {
    height: 45px;
    width: 140px;
  }

  .titleFirstWrap img, .titleSecondWrap img {
    height: 35px;
  }

  .titleSecondWrap {
    transform: translateX(-50px);
  }

  .titleSecondWrap, .titleFirstWrap {
    column-gap: 30px;
  }

  .list {
    grid-template-columns: 1fr;
    row-gap: 25px;
    margin-top: 70px;
  }

  .item {
    justify-content: center;
  }

  .itemText {
    max-width: 250px;
  }
}

@media screen and (max-width: 420px) {
  .titleSecondWrap {
    transform: translateX(-90px);
  }
}


