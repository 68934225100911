@import "src/assets/styles/default";

.wrap {
  margin-top: 100px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-top: 2px solid rgba(255,255,255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap img {
  cursor: pointer;
}

.list {
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-family: $text;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

.list li {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
  }
}