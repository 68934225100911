@import "src/assets/styles/default";

.wrap {
  width: 268px;
  height: 82px;
  position: relative;
  text-align: left;
}

.dec {
  width: 244px;
  margin-left: 12px;
  height: 50px;
  margin-top: 16px;
  background: red;
  position: absolute;
  z-index: 4;
  box-shadow: rgba(26, 184, 120, 1) 10px 4px 16px, rgba(26, 184, 120, 1) 0px 8px 24px, rgba(26, 184, 120, 1) 0px 0px 56px;
  opacity: 0;
  transition: 0.5s;
}

.wrap:hover .dec {
  opacity: 1;
}

.btn {
  z-index: 15;
  border: none;
  background: transparent;
  width: 268px;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: $title;
  font-size: 30px;
  line-height: 27px;
  color: #fff;
  column-gap: 21px;
  cursor: pointer;

}

.img {
  position: absolute;
  width: 268px;
  height: 82px;
  z-index: 5;
}

.lineRight, .lineLeft {
  transition: 0.5s;
}

.wrap:hover .lineRight {
  top: 10px;
  opacity: 0;
}

.wrap:hover .lineLeft {
  bottom: 10px;
  opacity: 0;
}

.lineRight {
  position: absolute;
  top: -20px;
  right: 0;
}

.lineLeft {
  position: absolute;
  bottom: -20px;
  left: 0;
}

