@import "src/assets/styles/default";

.wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-top: -150px;
}

.sectionContent {
  padding-top: 100px;
  padding-bottom: 200px;
}

.gradient {
  width: 100%;
  height: 100%;
  background:
  linear-gradient(
                  to top, rgba(4, 9, 26, 1),
                  rgba(4, 9, 26, .9) 10%,
                  rgba(4, 9, 26, .5) 30%,
                  rgba(4, 9, 26, .4) 40%,
                  rgba(4, 9, 26, .5) 60%,
                  rgba(4, 9, 26, 1) 75%,
                  rgba(4, 9, 26, 1) 90%
  );
  position: absolute;
}

.gradient2 {
  position: absolute;
  width: 100%;
  top: 10%;
  height: 80%;
  background:
  linear-gradient(
                  to right, rgba(12, 28, 87, .0),
                  rgba(12, 28, 87, .0) 20%,
                  rgba(12, 28, 87, .4) 30%,
                  rgba(127, 7, 56, .1) 70%,
                  rgba(0, 0, 0, .1) 90%
  );
  z-index: 10;
}

.container {
  display: grid;
  grid-template-columns: 450px 1fr;
  column-gap: 20px;
}

.item {
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: flex-start;
}

.itemActions {
  display: grid;
  grid-template-columns: 60px 1fr;
}

.itemTitle {
  font-family: $title;
  font-weight: 400;
  font-size: 19px;
  transform: translateY(2px);
  line-height: 19px;
}

.coolIcon {
  transform: translateY(-11px);
  position: absolute;
}

.simpleStar {
  padding-left: 22px;
  display: block;
  margin-top: 5px;
}

.itemText {
  font-family: $text;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
}

.list {
  display: grid;
  row-gap: 20px;
}

.roadmapText {
  max-width: 100%;
}

@media screen and (max-width: 1200px){
  .container {
    grid-template-columns: 1fr ;
    row-gap: 50px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 920px){
  .wrap {
    margin-top: -50px;
  }

  .gradient {
    padding-top: 150px;
  }
}

@media screen and (max-width: 700px){
  .wrap {
    margin-top: -50px;
  }

  .gradient {
    padding-top: 150px;
  }

  .item {
    grid-template-columns: 1fr;
  }

  .topTitle {
    margin-top: 30px;
    transform: translate(15px, -10px);
  }

  .container {
    padding-left: 0px;
  }
}

