@import "src/assets/styles/default";

.wrap {
  border-bottom: 1px solid rgba(255,255,255, 0.5);
  padding-bottom: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.description {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  max-width: 700px;
  transition: 0.5s;
  max-height: 0;
  opacity: 0;
  color: #cbd0d6;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding-right: 10px;
}

.icon {
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.iconRotate {
  transform: rotate(225deg);
}

.icon div {
  height: 1px;
  background: #fff;
  width: 20px;
  position: absolute;
}

.icon div:nth-child(2) {
  transform: rotate(90deg);
}

.title {
  font-family: $text;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.descriptionActive {
  max-height: 400px;
  opacity: 1;
  padding-top: 20px;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 17px;
    line-height: 26px;
  }
}
