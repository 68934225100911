@import "src/assets/styles/default";

.wrap {
  width: 143px;
  height: 45px;
  position: relative;
}

.dec {
  width: 101px;
  height: 26px;
  position: absolute;
  top: 9px;
  left: 22px;
  box-shadow: rgba(26, 184, 120, 1) 1px -4px 16px, rgba(26, 184, 120, 1) 0px 8px 24px, rgba(26, 184, 120, 1) 0px 0px 56px;
  opacity: 0;
  transition: 0.5s;
}

.wrap:hover .dec {
  opacity: 1;
}

.btn {
  border: none;
  background: transparent;
  width: 143px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: $text;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.img {
  position: absolute;
  width: 143px;
  height: 45px;
}

