@import 'src/assets/styles/default';

.background {
  width: 1100px;
  height: 500px;
  position: absolute;
}

.text {
  font-family: $title;
  font-size: 49px;
  line-height: 70px;
  margin-bottom: 60px;
}

.wrap {
  width: 1100px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  transform: translateY(-150px);
}


.content {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 50px 90px 50px 50px;
}

.grey {
  color: $grey;
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 50px;
}


@media screen and (max-width: 1200px){
  .wrap {
    width: 100%;
  }

  .background {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 1050px){
  .text {
    text-align: center;
    font-size: 40px;
    line-height: 55px;
  }

  .content {
    padding: 50px;
  }


  .buttons {
    flex-direction: column;
    row-gap: 30px;
  }

  .background {
    width: 100%;
    height: 560px;
  }

  .men {
    width: 400px;
  }

  .child {
    width: 300px;
  }
}

@media screen and (max-width: 800px){
  .text {
    font-size: 35px;
    line-height: 45px;
  }

  .sections {
    row-gap: 120px;
  }

  .wrap {
    transform: translateY(-0px);
  }

  .infoTitle {
    font-size: 48px;
    line-height: 55px;
  }

  .info {
    grid-row: 1;
    margin-top: -150px;
  }

  .textInfo, .textInfo2 {
    font-size: 18px;
    line-height: 24px;
  }

  .wrap {
    height: 620px;
  }
}



@media screen and (max-width: 650px){
  .background {
    height: 620px;
  }

  .info {
    height: 600px;
  }

  .men {
    width: 200px;
  }

  .child {
    width: 150px;
  }

  .infoContent {
    justify-content: flex-start;
  }

  .infoTitle {
    font-size: 45px;
    line-height: 55px;
  }

  .textInfo, .textInfo2 {
    font-size: 15px;
    line-height: 24px;
  }
}


@media screen and (max-width: 500px){
  .text {
    font-size: 22px;
    line-height: 32px;
  }
}


