.wrap {
  margin-top: 170px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  overflow-x: scroll;
  justify-content: center;
  padding: 0 30px;
}

.logoMock {
  width: 226px;
  height: 88px;
}

.partnerWrap {
  position: relative;
  margin-right: 0;
  width: 324px;
  height: 89px;
}

.partnerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dec {
  width: 187px;
  height: 87px;
}

.partnerWrap img {
  position: absolute;
}

.logoItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 189px;
  height: 89px;
}

.logoItem img {
  position: absolute;
}

@media screen and (max-width: 1400px) {

  .wrap {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 800px) {
  .partnerWrap, .partnerDec {
    width: 220px;
    height: 89px;
  }

  .wrap {
    margin-top: 100px;
  }
}