@import "src/assets/styles/default";

.itemSection {
  height: 597px;
  width: 325px;
  margin: 0 auto;
  overflow: hidden;
}

.itemBlock {
  position: absolute;
  z-index: 44;
}

.itemWrap {
  height: 597px;
  position: relative;
  width: 325px;
  margin: 0 auto;
}

.bgImage {
  position: absolute;
  width: 325px;
  height: 597px;
}



.content {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.content > img {
  width: 100%;
}

.title {
  font-family: $title;
  font-weight: 400;
  font-size: 38px;
  line-height: 71px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.text {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
}


.dec {
  position: absolute;
  bottom: 0;
  right: 0;
}