@import 'src/assets/styles/default';

.wrap {
  padding: 40px 0;
}

.headerWrap {
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
}

.container {
  overflow-x: scroll;
  transition: 1s;
}


.item {
  width: 271px;
  height: 332px;
  position: relative;
}

.itemWrap {
  width: 271px;
  height: 332px;
  position: relative;
}

.itemContainer {
  width: 271px;
  height: 332px;
  z-index: 14;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.imageBg {
  position: absolute;
  opacity: 0.3;
}

.itemContent {
  position: absolute;
}

.itemContainerImage {
  display: flex;
  justify-content: center;
  padding-left: 20px;
}

.itemContainer img {
  max-width: 250px;
  max-height: 298px;
}

.itemContent h3 {
  font-size: 40px;
  line-height: 45px;
  font-family: $title;
}

.itemContent p {
  font-size: 17px;
  line-height: 25px;
  font-family: $text;
  max-width: 220px;
  margin: 0 auto;
  margin-top: 20px;
}

.sliderImg {
  position: absolute;
  width: 274px;
  height: 332px;
}


.list {
  display: flex;
  column-gap: 29px;
  width: fit-content;
  padding-right: 60vw;
}

.header {
  display: grid;
  grid-template-columns: 1fr 140px;
  margin-bottom: 50px;
  column-gap: 40px;
  align-items: center;
}

.line {
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.lineActive {
  height: 1px;
  background-color: $lightGreen;
  transition: 0.5s;
}

.arrowLeft {
  transform: rotateY(180deg);
  cursor: pointer;
}

.arrowRight {
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: space-between;
}