@import "src/assets/styles/default";

.wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.container {
  height: 100%;
  width: 100%;
  background: linear-gradient(
                  to bottom,
                  rgba(70, 13, 214, .1),
                  rgba(70, 13, 214, .3) 15%,
                  rgba(70, 13, 214, .3) 35%,
                  rgba(70, 13, 214, .4) 55%,
                  rgba(70, 13, 214, .1) 75%,
                  #07091D

  );
}

.information {

}

.content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100vh;
  padding-top: 80px;
  align-items: center;
  padding-bottom: 150px;
}

.cardImg {
  opacity: 0;
  transition: 1s;
  scale: 0.8;
}

.cardImgActive {
  opacity: 1;
  scale: 1;
}

.title {
  font-family: $title;
  font-weight: 400;
  font-size: 114px;
  line-height: 110px;
}

.text {
  font-size: 19px;
  line-height: 31px;
  font-family: $text;
  margin-top: 21px;
  margin-bottom: 73px;
}

.green {
  color: $lightGreen;
}

.card {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnWrap {

}

@media screen and (max-width: 1300px){
  .cardImg {
    width: 320px;
  }
}

@media screen and (max-width: 1200px){
  .information {
    padding-left: 30px;
  }

  .title {
    font-family: $title;
    font-size: 77px;
    line-height: 75px;
  }
}

@media screen and (max-width: 1200px){
  .information {
    padding-left: 30px;
  }

  .title {
    font-family: $title;
    font-size: 77px;
    line-height: 75px;
  }
}

@media screen and (max-width: 900px){
  .wrap {
    height: auto;
  }

  .content {
    grid-template-columns: 1fr;
    row-gap: 70px;
  }

  .information {
    padding-left: 0;
  }

  .cardImg {
    width: 271px;
  }

  .title {
    font-size: 48px;
    max-width: 300px;
    margin: 0 auto;
    line-height: 59px;
  }

  .text {
    max-width: 500px;
    margin: 21px auto 73px auto;
  }

  .btnWrap {
    display: flex;
    justify-content: center;
  }

  .card {
    justify-content: center;
  }

  .content {
    height: auto;
    text-align: center;
  }
}