@import "src/assets/styles/default";

.bonusItem {

}

.bonusIconWrap {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonusIconBg {
  position: absolute;
}

.bonusTitle {
  margin-top: 20px;
  font-family: $text;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
}

.bonusList {
  font-family: $text;
  margin-top: 20px;
  display: grid;
  row-gap: 12px;
}

.bonusListItem {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: flex-start;
}

.bonusListCount {
  font-size: 15px;
  line-height: 26px;
  color: $lightGreen;
}

.bonusListText {
  line-height: 26px;
  color: rgba(255, 255, 255, 0.4);
}

@media screen and (max-width: 1050px){
  .bonusWrap {
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    text-align: center;
  }

  .bonusIconWrap {
    margin: 0 auto;
  }

  .bonusListItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px){
  .wrap {
    padding-top: 80px;
  }

  .information {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 30px;
  }

  .infoText {
    max-width: 500px;
    margin: 0 auto;
  }

  .play {
    transition: 0.5s;
  }
}

@media screen and (max-width: 600px){
  .bonusWrap {
    grid-template-columns: 1fr;
  }

  .bonusItem {
    background: rgba(0,0,0, 0.4);
    padding: 20px;
  }
}

