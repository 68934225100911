.wrap {
  display: none;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  flex-direction: column;
  cursor: pointer;
  z-index: 55;
}

.wrapActive {

}

.wrap > span {
  height: 3px;
  width: 100%;
  background: rgba(255,255,255, 1);
  display: block;
}

.wrap > span:nth-child(1) {
  transform: translate(-6px, -6px,);
  width: 60%;
  transition: 0.5s;
}

.wrap > span:nth-child(2) {
  transition: 0.2s;
}

.wrap > span:nth-child(3) {
  transform: translate(-6px, 6px);
  width: 60%;
  transition: 0.5s;
}
.wrapActive > span:nth-child(1) {
  transform: translate(0, 3px) rotate(-45deg);
  transform-origin: center;
  width: 100%;
}

.wrapActive > span:nth-child(2) {
  width: 0;
}

.wrapActive > span:nth-child(3) {
  transform: translate(0, -3px) rotate(45deg);
  transform-origin: center;
  width: 100%;
}


@media screen and (max-width: 900px){
  .wrap {
    display: flex;
  }
}