@import "src/assets/styles/default";

.block {
  
}

.wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  position: relative;
}

.postersWrap {

}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
                  to bottom, #07091D,
                  #07091D 25%,
                  rgba(0, 0, 0, .1) 30%,
                  rgba(0, 0,0, .5) 50%,
                  rgba(70, 13, 214, .4) 60%,
                  rgba(70, 13, 214, .5) 65%,
                  rgba(70, 13, 214, 0.4) 75%,
                  #07091D 90%
  );
}

.container {
  padding-top: 200px;
  padding-bottom: 300px;
}

.content {
  display: grid;
  grid-template-columns: 725px 1fr;
  column-gap: 30px;
}

.postersText {
  font-family: $text;
  font-size: 15px;
  line-height: 26px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.postersContainer {
  display: grid;
  grid-template-columns: 280px 1fr;
  column-gap: 20px;
  height: 520px;
}

.posterFirst, .posterSecond {
  width: 100%;
}

.postersContainer img {
  border-radius: 20px;
}

.iconWrap {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255, 0.3);
  border-radius: 10px;
}

.creationListItem {
  display: grid;
  grid-template-columns: 55px 1fr;
  padding: 20px 20px;
  cursor: pointer;
  transition: 0.3s;
}

.offer {
  transition: 1s !important;
  transform: translateY(100px);
  scale: 0.8;
}

.offerActive {
  transform: translateY(0px);
  scale: 1;
  transition: 1s !important;
}

.creationListItemActive {
  background: #000;
  border-radius: 20px;
}

.listItemContent h5 {
  font-family: $text;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 10px
}

.listItemContent p {
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.creationListWrap {

}

.creationList {

}

@media screen and (max-width: 1150px) {
  .content {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .postersText {
    max-width: 800px;
  }
}

.creationTitleImg {
  max-width: 100%;
}

@media screen and (max-width: 800px) {
  .content {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .postersText {
    max-width: 800px;
  }

  .postersContainer {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .postersContainer img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: block;
  }

  .offer {
    margin-top: -150px;
  }
}