@import "src/assets/styles/default";

.wrap {
  padding-bottom: 300px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  padding-top: 120px;
}

.posterWrap {
  position: relative;
}

.poster {
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  opacity: 0;
  transition: 1s;
}

.posterActive {
  opacity: 1;
}

.play {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid $lightGreen;
  background-color: $dark;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding-left: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 1s;
  margin-top: 600px;
  scale: 0;
}

.play:hover {
  background: $lightGreen;
}

.playActive {
  scale: 1;
  margin-top: 0;
}


.information {
  margin-top: 75px;
  display: grid;
  grid-template-columns: 500px 1fr;
  align-items: center;
}

.infoText {
  font-family: $text;
  font-size: 15px;
  line-height: 28px;
  transform: translateY(-3px);
}

.bonusImg {
  max-width: 100%;
}

.bonusWrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px;
  column-gap: 50px;
}

@media screen and (max-width: 1050px){
  .bonusWrap {
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    text-align: center;
  }
}

@media screen and (max-width: 900px){
  .wrap {
    padding-top: 80px;
  }

  .information {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 30px;
  }

  .infoText {
    max-width: 500px;
    margin: 0 auto;
  }

  .play {
    transition: 0.5s;
  }
}

@media screen and (max-width: 600px){
  .bonusWrap {
    grid-template-columns: 1fr;
  }
}
