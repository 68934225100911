@import "src/assets/styles/default";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
}

.wrap > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 517px
}

.teamPreview {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.item {
  max-width: 100%;
  height: 517px;
  width: 372px;
  position: relative;
}

.itemContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
}

.teamText {
  position: absolute;
}

.listItemBg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.listItemBgReverse {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.itemHeader {
  display: grid;
  grid-template-columns: 130px 1fr;
}

.iconWrap img {
  width: 110px;
  height: 110px;
  border-radius: 15px;
}

.itemRightIcon {
  display: flex;
  column-gap: 15px;
  margin-bottom: 12px;
}

.itemRightIcon > div {
  width: 33px;
  height: 33px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellowIcon {
  background-color: #F5C518;
  color: #262626;
  font-family: $text;
  font-size: 8px;
  font-weight: 700;
  height: 33px;
  width: 33px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.yellowIcon:hover {
  font-size: 10px;
}

.link {
  width: 33px;
  height: 33px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitterIcon {
  background-color: #4A99E9;
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 4px;
}

.twitterIcon:hover {
  font-size: 18px;
}

.itemName {
  font-family: $text;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
}

.itemPosition {
  margin-top: 1px;
  font-family: $text;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.4);
}

.itemTerms {
  margin-top: 30px;
}

.termText {
  margin-top: 10px;
  font-family: $text;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #FFFFFF;
}

.termItem {
  display: flex;
  align-items: flex-start;
  column-gap: 1px;
}

.listItemDec {
  position: absolute;
  left: 0;
  bottom: 0;
}

.teamItemLogo {
  height: 517px;
  max-width: 372px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamWhiteDec {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 84px;
}

.listItemDecReverse {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
}

@media screen and (max-width: 1190px) {
  .wrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .wrap {
    grid-template-columns: 1fr;
    margin-top: 100px;
  }


  .listItemBgReverse {
    transform: rotate(0deg);
  }

  .listItemDecReverse {
    right: auto;
    top: auto;
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 420px) {
  .item {
    width: 100%;
  }

  .teamWhiteDec {
    width: 78px;
  }

  .itemHeader {
    grid-template-columns: 110px 1fr;
  }

  .iconWrap img {
    width: 95px;
    height: 95px;
  }

  .listItemDecReverse, .listItemDec {
    scale: 0.9;
  }
}

