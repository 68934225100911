@import "src/assets/styles/default";
.container {
  width: 80px;
  height: 40px;
  position: relative;
}

.wrap {
  padding: 3px 8px;
  border: 3px solid $lightGreen;
  border-radius: 25px;
  width: 80px;
  background-color: #2a2a30;
  cursor: pointer;
  position: absolute;
  transition: 0.5s;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding-top: 2px;
}

.list {
  margin-top: 5px;
  display: grid;
  row-gap: 5px;
}

.value {

}