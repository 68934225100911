@import "src/assets/styles/default";

.wrap {
  height: 60px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  transition: 0.5s;
  margin-top: -100px;
}

.wrapRendered {
  margin-top: 0;
}

.section {
  max-width: 1400px;
  padding: 0 20px;
  height: 100%;
  margin: 0 auto;
  background-color: transparent;
  transition: 1s;
}

.wrapActive {
  background-color: #0e1114;
}

.container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.logoWrap {

}

.logo {
  transform: translateY(4px);
  cursor: pointer;
}

.contentWrap {
  display: flex;
  align-items: center;
  column-gap: 45px;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-family: $text;
  font-weight: 600;
  font-size: 15px;
}

.listDec {
  position: absolute;
  width: 0;
  height: 2px;
  background: #fff;
  bottom: -2px;
  transition: 0.5s;
}

.menu li:hover .listDec {
  width: 100%;
}



.menu li {
  cursor: pointer;
  padding: 3px 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px){
  .menu, .actions {
    display: none;
  }

  .logo {
    position: fixed;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
}