@import "src/assets/styles/default";

.btn {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
  cursor: pointer;
  position: relative;
  border: none;
  border-radius: 10px;
  background: #fff !important;
}

.dec {
  background: $lightGreen;
  width: 0;
  height: 0;
  position: absolute;
  transition: 0.3s;
  border-radius: 10px;
}

.btn:hover .dec {
  width: 100%;
  height: 100%;
}

.iconWrap {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.subscribeIcon {
  position: absolute;
  z-index: 5;
}

.greenIcon {
  position: absolute;
}

.text {
  font-family: $text;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  color: $black;
  cursor: pointer;
  z-index: 10;
}


@media screen and (max-width: 690px){
  .text {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  }

}